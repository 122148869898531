<template>

  <component :is="'script'" type="application/ld+json" data-pagespeed-no-transform v-for="( item, index ) in this.$store.state.json_ld" :key="index" v-html="JSON.stringify( item )"></component>

  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `Location Garde-meuble` }}</template>
  </metainfo>

  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" :key="Component"></component>
    </transition>
  </router-view>

  <Cookies />

</template>

<script>
import { Options, setup, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { key } from "./store";
import { defineAsyncComponent } from "vue";
import { useMeta } from "vue-meta";
import { bootstrap } from 'vue-gtag';

@Options( {
  components : {
    Cookies : defineAsyncComponent( () => import("./components/Cookies.vue") ),
    Loader  : defineAsyncComponent( () => import("./components/Loader.vue") )
  }
} )
export default class App extends Vue {
  loading = true;
  search  = null;
  store   = useStore( key );

  handleResize()
  {
    this.store.state.window.width  = window.innerWidth;
    this.store.state.window.height = window.innerHeight;
  }

  created()
  {
    window.addEventListener( "resize", this.handleResize );
    this.handleResize();
  }

  destroyed()
  {
    window.removeEventListener( "resize", this.handleResize );
  }

  mounted()
  {
    this.$watch( () => this.$route.params,
      ( toParams, previousParams ) => {
        //console.log( "Route : ", this.$route.name );
        //console.log( "Route params : ", toParams );
      }
    );

    setTimeout( () => {
      bootstrap().then( ( gtag ) => { /*console.log( "Analytics loaded !" ) */ } )
    }, 1000 );
  }
}
</script>

<style lang="scss">
//@import "@/assets/scss/global.scss";
</style>
