import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

// define your typings for the store state
export interface State
{
  loading : boolean,
  avis_modal : boolean,
  rdv_modal : boolean,
  callback_modal : boolean,
  contact_modal : boolean,
  address_modal : boolean,
  delay : number,
  window : any,
  search : any,
  gm : any,
  current : any,
  error : string,
  icons : any,
  counter : any,
  globalCounter : any,
  home_data : any,
  base_url : any,
  apiVue : string,
  api : string,
  json_ld : any
}

// define injection key
export const key : InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>( {
  state   : {
    base_url : process.env.BASE_URL,
    apiVue   : "https://api.location-gardemeuble.fr/vue/",
    api      : "https://api.location-gardemeuble.fr/",
    window   : {
      width  : 0,
      height : 0
    },
    search   : null, // Search page
    gm       : null, // GM page
    current  : null, // Guide page
    loading  : true,
    delay    : 200, // Delay before hiding the loading screen (ms)
    error    : "",
    icons    : false,
    
    // GM page
    avis_modal     : false,
    rdv_modal      : false,
    callback_modal : false,
    contact_modal  : false,
    address_modal  : false,
    
    json_ld : [
      // Organization JSON-LD
      {
        "@context"     : "https://schema.org",
        "@type"        : "Organization",
        "url"          : "https://www.location-gardemeuble.fr/",
        "logo"         : "https://www.location-gardemeuble.fr/img/logo.gif",
        "name"         : "Location Gardemeuble",
        "contactPoint" : {
          "@type"       : "ContactPoint",
          "telephone"   : "+33 1 86 65 09 70",
          "contactType" : "customer service"
        }
      },
      
      // SelfStorage JSON-LD
      {
        "@context"        : "https://schema.org",
        "@type"           : "SelfStorage",
        "openingHours"    : "Mo-Su 00:00-24:00",
        "paymentAccepted" : "credit card",
        "aggregateRating" : {
          "@type"       : "AggregateRating",
          "ratingValue" : "4.8",
          "ratingCount" : 5225
        },
        "areaServed"      : "France",
        "address"         : {
          "@type"           : "PostalAddress",
          "addressLocality" : "France"
        },
        "telephone"       : "01 86 65 09 70",
        "url"             : "https://www.location-gardemeuble.fr/",
        "name"            : "Location Gardemeuble",
        "logo"            : "https://www.location-gardemeuble.fr/img/logo.gif",
        "image"           : "https://www.location-gardemeuble.fr/img/logo.gif"
      }
    ],
    
    home_data : {
      big  : [],
      data : []
    },
    
    counter : function( type : any, gm_id : number, next : any, wait = false ) {
      let headers = new Headers();
      
      fetch( store.state.apiVue + "gm/" + gm_id + "/counter/" + type, {
        method  : "GET",
        headers : headers,
        cache   : "no-cache"
      } ).then( ( res ) => {
        return res.json();
      } ).then( ( json ) => {
        if( wait )
          next();
      } ).catch( error => {
        console.error( error );
      } );
      
      // Don't wait for the promise to be resolved
      if( !wait )
        next();
    },
    
    globalCounter : function( type : any, next : any, wait = false ) {
      let headers = new Headers();
      
      fetch( store.state.apiVue + "counter/" + type, {
        method  : "GET",
        headers : headers,
        cache   : "no-cache"
      } ).then( ( res ) => {
        return res.json();
      } ).then( ( json ) => {
        if( wait )
          next();
      } ).catch( error => {
        console.error( error );
      } );
      
      // Don't wait for the promise to be resolved
      if( !wait )
        next();
    }
  },
  getters :
    {
      isMobile  : state => {
        return state.window.width > 0 && state.window.width < 1024;
      },
      isDesktop : state => {
        return state.window.width > 0 && state.window.width >= 1024;
      }
    }
} );
